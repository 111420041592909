import lazyLoadHelper from '@utils/lazyLoadHelper';

import vaultImageSmall from '@assets/images/apps/vault_small.png';
import vaultImage from '@assets/images/launcher/vault.png';
import { VAULT_URL } from '@constants/common';

import { type AppConfig } from '@typing/AppConfig';

import { routes } from './routes';

// We are currently rolling out new Vault (vault V2, which is an external application ) to individual namespaces.
// This conditional defines which namespaces get Vault V1 and which
// get Vault V2 (which have different domains) on their homepage.
// In addition Vault V1 is required to be a defined route in order to serve the model viewer.
// This is temporary and will be removed once all namespaces use Vault V2 and model viewer has been migrated to be a separate module.
// Note: platform-dev requires old Vault (V1) for cypress tests

const visibleVaultV2Namespaces = [
  // Product Namespaces
  'platform-sandbox',
  'internal',
  //
  // Delivery Namespaces
  'ampere',
  'bramley',
  'donatello',
  'interstellar',
  'isaac',
  'joule',
];

export const configVaultV1: AppConfig = {
  appName: 'Vault',
  description: 'Securely upload and share data',
  hidden: visibleVaultV2Namespaces.includes(
    process.env.PLATFORM_NAMESPACE || '',
  ),
  icon: vaultImageSmall,
  id: 'vault1',
  imageSrc: vaultImage,
  navigationLinks: [],
  path: 'app/vault',
  root: lazyLoadHelper(() => import('@vault/pages/index')),
  routes,
};

export const configVaultV2: AppConfig = {
  appName: 'Vault',
  description: 'Securely upload and share data',
  external: true,
  hidden: !visibleVaultV2Namespaces.includes(
    process.env.PLATFORM_NAMESPACE || '',
  ),
  id: 'vault2',
  imageSrc: vaultImage,
  navigationLinks: [], // external app don't have any child routes
  path: VAULT_URL,
  routes: [], // external app don't have any child routes
};

export default configVaultV1;
