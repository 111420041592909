/* eslint-disable sort-keys-fix/sort-keys-fix */
/* eslint-disable sort-keys */

import { EmptyStateChildrenType } from '@components/EmptyState/EmptyStateChildren';

export const MAX_PIPELINE_NAME_LENGTH = 300;

export const PIPELINE_RUN_NAME_REGEX =
  /[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*/;

export const PIPELINE_RUN_NAME_INPUT_REGEX = /^[a-zA-Z0-9 _-]*$/;

export const DEFAULT_PIPELINES_PAGE_SIZE = 10;

export const RECENT_COLLECTIONS_NUMBER = 5;

export const LINK_COPIED_TIMEOUT = 3000; // 3 seconds

export const DEBOUNCE_DELAY = 700; // 0.7 seconds

export type HeaderType = {
  [index: string]: { disabled?: boolean; id: string; name: string };
};

interface EmptyStateMessageType {
  [key: string]: (input?: any) => EmptyStateChildrenType;
}

export const PIPELINES_MESSAGING: EmptyStateMessageType = {
  loading: () => ({
    title: 'Loading...',
    message: 'Hang on whilst we load your pipelines',
  }),
  noRegistered: () => ({
    title: 'No pipelines registered',
    message: 'Register a pipeline to run it using Flow',
  }),
  noPipelinesFound: (query: string) => ({
    title: 'No pipelines found',
    message: `No pipelines were found for ${query}. Try searching with another keyword`,
  }),
};

export const RUNS_MESSAGING: EmptyStateMessageType = {
  loading: (isInHomePage?: boolean) => ({
    title: 'Loading...',
    message: `Hold on whilst we loading your runs${isInHomePage ? ' in progress' : ''}`,
  }),
  noRunsInProgress: (isInHomePage?: boolean) => ({
    title: `You have no runs${isInHomePage ? ' in progress' : ''}`,
    message: 'Start a pipeline here to view its progress',
    messageLink: {
      to: '/app/flow/pipelines',
      label: 'here',
    },
  }),
  error: (isInHomePage?: boolean) => ({
    title: 'Oops!',
    message: `There has been an issue loading your runs${isInHomePage ? ' in progress' : ''}. Try again or contact us if this issue persists`,
  }),
};

export const YAML_CODE_EDITOR_MESSAGING = {
  parameterNotConfigured: (parameterName?: string, configName?: string) => ({
    message: `Parameter "${parameterName}" was not defined in "${configName}". Please re-register the pipeline if you want to include this parameter.`,
  }),
  missingParameterValue: (parameterName?: string, configName?: string) => ({
    message: `The value of "${parameterName}" parameter was not provided in YAML. This value will be overwritten by default provided in "${configName}"`,
  }),
};

export const PIPELINES_TABLE_HEADER_NAMES: HeaderType = {
  project_name: {
    id: 'project_name',
    name: 'Project name',
  },
  version: {
    id: 'version',
    name: 'Version',
  },
  name: {
    id: 'pipeline_name',
    name: 'Pipeline name',
  },
  // hide for when it becomes available in BE
  // type: {
  //   id: 'type',
  //   name: 'Type',
  // },
  created_by: {
    id: 'created_by',
    name: 'Created by',
  },
  updated_by: {
    id: 'last_run_at',
    name: 'Last run',
  },
};

export const RUNS_TABLE_HEADER_NAMES: HeaderType = {
  name: {
    id: 'run_name',
    name: 'Run name',
  },
  project_name: {
    id: 'project_name',
    name: 'Project name',
  },
  version: {
    id: 'version',
    name: 'Version',
  },
  pipeline_name: {
    id: 'pipeline_name',
    name: 'Pipeline name',
  },
  created_by: {
    id: 'created_by',
    name: 'Created by',
  },
  // hide for when it becomes available in BE
  // type: {
  //   id: 'type',
  //   name: 'Type',
  // },
  start_time: {
    id: 'start_time',
    name: 'Start time',
  },
};
