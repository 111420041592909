import type { FC, ReactNode } from 'react';

interface RangeLabelProps {
  children: ReactNode;
  left?: number;
  right?: number;
}

const RangeLabel: FC<RangeLabelProps> = ({
  left,
  right,
  children,
  ...props
}) => {
  return (
    <div
      className={`flex w-full items-center justify-between label-1 text-on-surface-subtle `}
      {...props}
    >
      {left !== undefined && <span className="pr-[12px]">{left}</span>}
      <div className="flex-grow">{children}</div>
      {right !== undefined && <span className="pl-[12px]">{right}</span>}
    </div>
  );
};

export { RangeLabel, type RangeLabelProps };
